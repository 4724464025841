<template>
  <div class="container custom-container">
    <header class="sticky-header">
      <nav>
        <a href="#about">About</a>
        <a href="#services">Services</a>
        <a href="#approach">Approach</a>
      </nav>
    </header>
    <div id="about" class="hero-section">
      <h1>Wallop Labs LLC</h1>
      <p class="lead">
        Innovative Product Management and Technology Consulting
      </p>
    </div>

    <main>
      <section id="about">
        <div class="content-section">
          <h2>About Wallop Labs</h2>
          <p>
            Wallop Labs LLC is a product management and technology consulting firm. With over 15 years of experience and a portfolio of 80+ projects, we specialize in delivering innovative solutions across a broad range of industries, including web, mobile, social, geo, live streaming video, IoT/hardware devices, Web3/blockchain, and applied AI.
          </p>
        </div>
      </section>

      <section id="services" class="content-section services-section">
        <h2>Services & Areas of Expertise</h2>
        <p>Wallop Labs offers a range of services tailored to meet the unique needs of our clients. Our expertise spans several key areas, enabling us to provide comprehensive support for projects of all sizes.</p>
        
        <div class="service-list">
          <div class="service-item">
            <h4>Applied AI Discovery & MVP Prototyping</h4>
            <ul>
              <li>Easy-to-implement AI solutions that drive business outcomes.</li>
              <li>Explore client insights, validate ideas, and swiftly deploy prototypes and MVPs.</li>
              <li>Pioneering experience in applied AI solutions.</li>
            </ul>
          </div>

          <div class="service-item">
            <h4>Generative Research Strategy & Interviews</h4>
            <ul>
              <li>Identify hidden customer opportunities and validate product hypotheses.</li>
              <li>Develop customized research and interview guides.</li>
              <li>Align with frameworks like The Mom Test and Continuous Discovery Habits.</li>
            </ul>
          </div>

          <div class="service-item">
            <h4>Business Analysis</h4>
            <ul>
              <li>Comprehensive requirements gathering and modeling for large-scale projects.</li>
              <li>Expertise in UML analysis and adaptable documentation.</li>
            </ul>
          </div>

          <div class="service-item">
            <h4>Product Analytics & Product Data Science</h4>
            <ul>
              <li>Deep analysis of product usage, identifying user drop-off points, and interpreting A/B testing data.</li>
              <li>Forensic analysis of user journeys and data science insights to drive growth.</li>
            </ul>
          </div>

          <div class="service-item">
            <h4>Willingness-to-Pay Studies & eCommerce Modeling</h4>
            <ul>
              <li>Conduct studies to determine customers' willingness to pay.</li>
              <li>Shape product roadmaps, partnerships, and revenue strategies.</li>
              <li>Apply Bayesian probability modeling for reliable purchase rate predictions.</li>
            </ul>
          </div>

          <div class="service-item">
            <h4>Competition & Whitespace Strategy Workshops</h4>
            <ul>
              <li>Identify new opportunities and differentiate your products in crowded markets.</li>
              <li>Facilitate practical processes including Strategy Canvas and Customer Journey Utility Map.</li>
            </ul>
          </div>

          <div class="service-item">
            <h4>Design Discovery Workshops</h4>
            <ul>
              <li>Unblock problem identification and produce validated product concepts.</li>
              <li>Available in 1-day, 3-day, and 5-day versions to suit your needs.</li>
            </ul>
          </div>

          <div class="service-item">
            <h4>GTM Workbacks aka Business Plans</h4>
            <ul>
              <li>Create customer-centric business plans that work backwards from customer satisfaction.</li>
              <li>Align product definitions with stakeholder expectations and market needs.</li>
            </ul>
          </div>

          <div class="service-item">
            <h4>Omnichannel User Journey Mapping & Funnels Analysis</h4>
            <ul>
              <li>Comprehensive mapping of full-funnel user journeys to identify opportunities for improvement.</li>
              <li>Integrate omnichannel data for a unified view of customer behavior.</li>
            </ul>
          </div>

          <div class="service-item">
            <h4>Digital Product Growth Optimization</h4>
            <ul>
              <li>Transform your products and services into self-sustaining growth engines.</li>
              <li>Integrate feedback loops that drive meaningful outcomes and sustainable growth.</li>
            </ul>
          </div>

          <div class="service-item">
            <h4>BI Analysis & Dashboarding</h4>
            <ul>
              <li>Define essential KPIs and create dashboards using platforms like Tableau and Metabase.</li>
              <li>Deliver refined insights based on your data warehouse specifics.</li>
            </ul>
          </div>

          <div class="service-item">
            <h4>Agile Coaching & Scrum Management</h4>
            <ul>
              <li>Coaching on Agile practices and managing software delivery teams for consistent quality and performance.</li>
              <li>Guide teams through group development stages to high performance.</li>
            </ul>
          </div>

          <div class="service-item">
            <h4>Software Delivery Team Troubleshooting</h4>
            <ul>
              <li>Evaluate team structure, processes, and skills to identify and address upstream issues.</li>
              <li>Create aligned action plans to improve execution.</li>
            </ul>
          </div>

          <div class="service-item">
            <h4>Group Decision-Making Facilitation</h4>
            <ul>
              <li>Facilitate effective group decision-making using frameworks like SPADE.</li>
              <li>Ensure alignment and clarity in decision-making processes.</li>
            </ul>
          </div>

          <div class="service-item">
            <h4>Product Manager Coaching</h4>
            <ul>
              <li>Coaching for junior product managers to help them master their trade.</li>
              <li>Build cross-disciplinary experience on the job.</li>
            </ul>
          </div>

          <div class="service-item">
            <h4>Internal Product Accelerator Program</h4>
            <ul>
              <li>Foster innovation within your company and launch new products.</li>
              <li>Initiate manageable projects and achieve product/market fit through public iterations.</li>
            </ul>
          </div>
        </div>
      </section>

      <section id="approach" class="content-section approach-section">
        <h2>Our Approach</h2>
        <p>
          Wallop Labs blends a rare trove of broad and deep experience with industry-leading best practices, including Blue Ocean Strategy, Silicon Valley Product Group, Google Ventures Design Sprints, Amazon Workbacks, and Agile methodologies. Our methods are tailored to suit different stages of product maturity, from finding product-market fit to scaling and exploring adjacent innovations.
        </p>
      </section>

    </main>
    <footer>
      <p>
        &copy;2024 Wallop Labs LLC. 4400 S. Monaco St., Denver, Colorado. USA.
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  setup() {
    return {};
  }
}
</script>

<style>
/* Reset and body styles */
body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  color: #333;
  background-color: #f4f4f9;
}

/* Header styles */
.sticky-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #007BFF;
  z-index: 1000;
  border-bottom: 2px solid #0056b3;
  padding: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

nav a {
  padding: 10px 20px;
  text-decoration: none;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

nav a:hover {
  color: #e2e2e2;
}

/* Hero section */
.hero-section {
  padding: 100px 20px;
  text-align: center;
  background: linear-gradient(to right, #007BFF, #0056b3);
  color: white;
}

.hero-section h1 {
  font-size: 3em;
  margin-bottom: 10px;
}

.hero-section .lead {
  font-size: 1.5em;
  font-weight: 300;
}

/* Content sections */
.content-section {
  padding: 40px 20px;
  background-color: white;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #007BFF;
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
}

.service-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.service-item {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.service-item h4 {
  color: #0056b3;
  margin-bottom: 10px;
}

.service-item ul {
  padding-left: 20px;
}

.service-item ul li {
  margin-bottom: 10px;
}

/* Footer styles */
footer {
  padding: 20px;
  background-color: #007BFF;
  color: white;
  text-align: center;
  border-top: 2px solid #0056b3;
}
</style>
